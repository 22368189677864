import * as __ from 'firebase'; const firebase = __.default;

export default function InitializeFirebase() {
    const config = {
        apiKey: 'AIzaSyBwdfAkZeBqPiTMBRqZHmOuAHYEWsGZhpw',
        authDomain: 'authentic-city-church.firebaseapp.com',
        databaseURL: 'https://authentic-city-church.firebaseio.com',
        projectId: 'authentic-city-church',
        storageBucket: 'authentic-city-church.appspot.com',
        messagingSenderId: '1018846444829'
    }
    firebase.initializeApp(config)
}